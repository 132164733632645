import { Link } from "gatsby"
import React from "react"

import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Dropdown from "react-bootstrap/Dropdown"
import Nav from "react-bootstrap/Nav"

import { ReactComponent as Logo } from "./MIM_Logo_HorizVariant.svg"
import { ReactComponent as LogoMobile } from "./MIM_Logo_HorizVariant-2.svg"

import pages from '../../data/pages'

import { navbar, navbarBrandMobile, navbarBrand, navbarToggler, navbarCollapse, navItem } from "./navbar.module.scss"
import "./navbar.scss"

import SocialNav from '../socialNav'

export default ({ siteTitle }) => {

  const handleScroll = () => {
    setTop(window.scrollY === 0);
  };
  // // console.log(typeof asdf)
  const [top, setTop] = React.useState(true);

  React.useEffect(() => {
    // // console.log(top)
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [top]);


  return (
    <Navbar
      bg="clear"
      expand="lg"
      variant="dark"
      className={navbar + ` ${top ? "" : "not-top"}`}
      fixed="top"
    >
      <section className="custom-container">
        <Navbar.Brand className={`${navbarBrand}`}>
          <Link to={"/"}>
            <Logo></Logo>
          </Link>
        </Navbar.Brand>
        <Navbar.Brand className={navbarBrandMobile}>
          <Link to={"/"}>
            <LogoMobile></LogoMobile>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          className={`${navbarToggler}`}
          aria-controls="basic-navbar-nav"
          style={{ position: 'relative', zIndex: '1' }} />
        <Navbar.Collapse
          className={navbarCollapse}
          id="basic-navbar-nav">
          <Nav className="mr-auto">
            {pages.map((item, index) => <Nav.Item className={navItem} key={index}>
              {item.children ? <Dropdown>
                <Link className="mx-0" partiallyActive={true} activeClassName='active' to={item.path}>{item.title}</Link>
                <Dropdown.Toggle split variant="none" />
                <Dropdown.Menu>
                  {
                    item.children.map((subItem, subIndex) => (<Link key={subIndex} activeClassName='active' to={subItem.path}>{subItem.title}</Link>))
                  }
                </Dropdown.Menu>
              </Dropdown> : <Link className="mx-0" partiallyActive={true} activeClassName='active' to={item.path}>{item.title}</Link>}
            </Nav.Item>)}
            <Nav.Item className={navItem} >
              <SocialNav></SocialNav>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </section>
    </Navbar>)
}

