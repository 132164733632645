/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from './footer'

import "./layout.scss"
export default ({ className, children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header 
      className={className ? className : ""} 
      siteTitle={data.site.siteMetadata.title} />
      <div className="layout-body">
        <main style={{backgroundColor: "white"}}>{children}</main>
      </div>
      <Footer></Footer>
      <div className="footer-background"></div>
    </>
  )
}

