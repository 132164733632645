import PropTypes from "prop-types"
import React from "react"
import Navbar from './navbar'
import "bootstrap/dist/css/bootstrap.min.css"
import './header.scss'

const Header = ({ className }) => (
  <header className={className}>
    <Navbar></Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
