import React from 'react'
import { ReactComponent as FbLogo } from "./facebook-f-brands.svg"
import { ReactComponent as InstagramLogo } from "./instagram-brands.svg"
import { ReactComponent as TwitterLogo } from "./twitter-brands.svg"

export default () => {
    return <span>
        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Mid-Michigan-Ag-Service-LLC-790879047788578/"><FbLogo></FbLogo></a>
        <a target="_blank" rel="noreferrer" href="https://twitter.com/mid_ag?lang=en"><TwitterLogo></TwitterLogo></a>
        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/midmichiganag/"><InstagramLogo></InstagramLogo></a>
    </span>
}