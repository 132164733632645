import React from "react"
import "./footer.scss"
import pages from "../../data/pages"
import { Link } from "gatsby"
import SocialNav from "../socialNav"
import { ReactComponent as Logo } from "./MIM_Logo_VertVariant.svg"

const Layout = () => {
  return (
    <footer className="footer">
      <div>
        <Logo></Logo>
      </div>
      <nav>
        {pages.map(item => (
          <Link key={item.path} to={item.path}>
            {item.title}
          </Link>
        ))}
        <SocialNav></SocialNav>
      </nav>

      <span className="copyright">
        ©{new Date().getFullYear()} Copyright MidMichigan Ag Service
        <span className="desktop-bullet"> · </span>
        <br className="mobile-break"></br>
        branding &amp; website development by{" "}
        <a target="_blank" href="https://www.bluespacecreative.com/">
          <strong>blue</strong>space creative, inc
        </a>
        .
      </span>
    </footer>
  )
}

export default Layout
